import React, { useState, useRef } from "react";
import styles from "./breaking.module.css"

class Breaking extends React.Component {

    constructor(props){
        super(props)
    }

    render() {
        return <div className={styles.breaking}>
            <span className={styles.bold}>MAX IS BACK: </span>
            Am 20. August empfängt euch Max Cuap – gemeinsam mit dem unverkennbaren Baze und NacNecc im FrachtRaum.
        </div>
    }
}

export default Breaking