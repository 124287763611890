import React from "react"

import PathSVG from "../svgs/path.svg"
import PathSmallSVG from "../svgs/path_klein.svg"
import styles from "./travel_path.module.css"
const TravelPath = () => (
    <div className={styles.pathContainer}>
        <canvas width="5726" height="2976.4"></canvas>
        <PathSVG className={styles.path} />
        <PathSmallSVG className={styles.pathSmall} />
    </div>
)

export default TravelPath