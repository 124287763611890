import React from "react"

import { Link } from "gatsby"

import styles from "./menu.module.css"

class Menu extends React.Component {
    render() {
        return <ol className={styles.menu}>
            <li>
                <Link to="/max">
                    Who's Max?
                </Link>
            </li>
            <li>
                <Link to="/tickets">
                    Tickets
                </Link>
            </li>
            <li>
                <Link to="/events">
                    Events
                </Link>
            </li>
            <li>
                <Link to="/organization">
                    Organisation
                </Link>
            </li>
        </ol>
    }
}

export default Menu
