import React from "react"

import SEO from "../components/seo"
import Map from "../components/map"
import Path from "../components/travel_path"
import Menu from "../components/menu"
import Breaking from "../components/breaking"
import Stamp from "../components/stamp"
import styles from "./home.module.css"

const IndexPage = () => (
  <div className={styles.homePage}>
    <SEO title="Home" />
    <h1 className={styles.mainHeader}>Max Cuap</h1>
    <Breaking />
    <div className={styles.mapWrapper}>
      <Map />
      <Path />
      <Menu />
      
      {/* <Signature /> */}
    </div>
    {/* <Stamp /> */}

  </div>
)

export default IndexPage
