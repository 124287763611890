import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import styles from "./map.module.css"
const Map = () => (
    <StaticQuery
        query={graphql`
        query {
            desktop: file(relativePath: { eq: "map_large.png" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobile: file(relativePath: { eq: "map_small.png" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
                `}
        render={data => {
            return (
                <>
                    <div className={styles.mapContainer}>
                    <Img
                            fluid={data.desktop.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""></Img>
                    </div>
                    <div className={styles.mapSmallContainer}>
                        <Img
                            fluid={data.mobile.childImageSharp.fluid}
                            objectFit="cover"
                            objectPosition="50% 50%"
                            alt=""></Img>
                    </div>
                </>
            )
        }}
    />
)

export default Map